import GlobalLayout from "components/page/GlobalLayout";
import React from "react";
import Helmet from "react-helmet";
import MarketPlacePage from "components/integrations/marketplace/MarketPlacePage";

export default function Integrations() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>Rhombus Integrations and Open API</title>
        <meta
          name="description"
          content="Integrate Rhombus with the best access control, vape sensors, and more. Unlock greater value and new capabilities by viewing the Rhombus API at https://apidocs.rhombussystems.com/"
        />
      </Helmet>
      <MarketPlacePage />
    </GlobalLayout>
  );
}
