import { atom } from "jotai";

export const activeFilterAtom = atom("All");
export const filterTitleAtom = atom("All");
export const currentIndexAtom = atom(0);
export const loadingAtom = atom(true);
export const categoriesAtom = atom([]);
export const searchArrayAtom = atom([]);
export const selectionsAtom = atom({});
export const searchQueryAtom = atom("");
