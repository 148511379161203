import {
  AppIcon,
  AppIconWrapper,
  FeedLoader,
  GradientTag,
  GreenTag,
  GreyTag,
  IntegrationCard,
  IntegrationCardHeader,
  NotFoundContainer,
  Tag,
  TagWrapper,
} from "components/integrations/marketplace/styles/MarketPlace-css";
import {
  FancyTextLink,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import { Link } from "gatsby";
import { useAtomValue } from "jotai";
import React from "react";

import { loadingAtom } from "./atoms/atoms";
import LogoSmall from "./img/logo-icon-white.png";
import Illustration from "./img/not-found.svg";
import { scrollToForm } from "./util/functions";

const Apps = ({ data }) => {
  const loading = useAtomValue(loadingAtom);

  const renderTagType = tag => {
    switch (tag) {
      case "New":
        return (
          <Tag>
            <p>{tag}</p>
          </Tag>
        );
      case "3rd Party":
        return (
          <GreyTag>
            <p>{tag}</p>
          </GreyTag>
        );
      case "Coming Soon":
        return (
          <Tag>
            <p>{tag}</p>
          </Tag>
        );
      case "Featured":
        return (
          <GreenTag>
            <p>{tag}</p>
          </GreenTag>
        );
      case "Certified":
        return (
          <GradientTag>
            <img src={LogoSmall} />
            <p>{tag}</p>
          </GradientTag>
        );
      case "Empty":
        return <></>;
      default:
        break;
    }
  };

  if (loading) {
    return (
      <FeedLoader>
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </FeedLoader>
    );
  }
  if (!data || data.length === 0) {
    return (
      <NotFoundContainer>
        <img src={Illustration} alt="not found" />
        <p>
          We couldn&apos;t find any apps matching your search. Try editing your
          search or{" "}
          <FancyTextLink onClick={() => scrollToForm("#marketplace-form")}>
            request one here.
          </FancyTextLink>
        </p>
      </NotFoundContainer>
    );
  }
  return data.map(item => {
    const tags = item.node.frontmatter.tags;
    const draft = item.node.frontmatter.draft;
    const slug = draft ? null : item.node.fields.slug;
    return (
      <IntegrationCard as={Link} to={slug} key={item.id} draft={draft}>
        <IntegrationCardHeader>
          <AppIconWrapper>
            <img
              src={item.node.frontmatter.appIcon}
              alt={item.node.frontmatter.appName}
              className={AppIcon}
            />
          </AppIconWrapper>
          <TagWrapper>
            {tags &&
              tags.map(tag => {
                return renderTagType(tag);
              })}
          </TagWrapper>
        </IntegrationCardHeader>
        <h3>{item.node.frontmatter.appName}</h3>
        <MainParagraph>{item.node.frontmatter.shortDescription}</MainParagraph>
      </IntegrationCard>
    );
  });
};

export default Apps;
