import useIsMobile from "hooks/useIsMobile";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import React, { useEffect, useMemo, useRef } from "react";

import {
  activeFilterAtom,
  categoriesAtom,
  filterTitleAtom,
  searchArrayAtom,
  searchQueryAtom,
  selectionsAtom,
} from "./atoms/atoms";
import { BREAK_POINT, COLLECTIONS, WORKS_WITH } from "./lib/constants";
import {
  CategoryCol,
  MultiSelection,
  MultiSelectionWrapper,
  Selection,
  SelectionContainer,
  SelectionTitle,
} from "./styles/MarketPlace-css";
import {
  cleanData,
  findQueryInIntegration,
  formatSearchFilterTitle,
  handleFilterType,
} from "./util/functions";

const Categories = ({ data, setCurrentFilter }) => {
  const setFilterTitle = useSetAtom(filterTitleAtom);
  const [activeFilter, setActiveFilter] = useAtom(activeFilterAtom);
  const [searchArray, setSearchArray] = useAtom(searchArrayAtom);
  const [selections, setSelections] = useAtom(selectionsAtom);

  const searchQuery = useAtomValue(searchQueryAtom);

  const selectionsRef = useRef(selections);
  selectionsRef.current = selections;

  const categories = useAtomValue(categoriesAtom);

  const { isMobile } = useIsMobile(BREAK_POINT);

  useEffect(() => {
    WORKS_WITH.forEach(item => {
      setSelections(prev => ({ ...prev, [item]: { checked: false } }));
    });
    setSearchArray([]);
  }, []);

  useMemo(() => {
    const onlyTrue = Object.keys(selectionsRef.current).filter(
      key => selectionsRef.current[key].checked
    );
    setSearchArray(onlyTrue);
  }, [selections]);

  useMemo(() => {
    const preFilteredData =
      searchQuery.length >= 1
        ? findQueryInIntegration(searchQuery, data)
        : data;
    const filteredByCat = preFilteredData.map(item => {
      return handleFilterType(activeFilter, item);
    });
    const filteredByWorksWith = filteredByCat.map(item => {
      const found = item.filter(el =>
        searchArray.every(element =>
          el.node.frontmatter.worksWith.includes(element)
        )
      );
      return found;
    });
    if (searchArray.length >= 1) {
      const resultData =
        filteredByWorksWith.flat().length <= 6
          ? filteredByWorksWith.flat()
          : cleanData(filteredByWorksWith);
      setCurrentFilter(resultData);
      if (searchQuery.length >= 1) {
        setFilterTitle(formatSearchFilterTitle(resultData, searchQuery));
      } else {
        setFilterTitle(activeFilter);
      }
    } else {
      const resultData =
        filteredByCat.flat().length <= 6
          ? filteredByCat.flat()
          : cleanData(filteredByCat);
      setCurrentFilter(resultData);
      if (searchQuery.length >= 1) {
        setFilterTitle(formatSearchFilterTitle(resultData, searchQuery));
      } else {
        setFilterTitle(activeFilter);
      }
    }
  }, [activeFilter, searchArray, searchQuery]);

  const handleMultiSelect = filter => {
    const checked = selections[filter].checked;
    setSelections({ ...selections, [filter]: { checked: !checked } });
  };

  return (
    <CategoryCol>
      <SelectionContainer>
        <SelectionTitle>Collections</SelectionTitle>
        {COLLECTIONS.map(item => (
          <Selection
            key={item}
            onClick={() => {
              setActiveFilter(item);
              setFilterTitle(item);
            }}
            active={activeFilter === item}
          >
            {item}
          </Selection>
        ))}
      </SelectionContainer>
      <SelectionContainer>
        <SelectionTitle>Categories</SelectionTitle>
        <Selection
          key="All"
          onClick={() => {
            setActiveFilter("All");
            setFilterTitle("All");
          }}
          active={activeFilter === "All"}
        >
          All
        </Selection>
        {categories.map(item => (
          <Selection
            key={item}
            onClick={() => {
              setActiveFilter(item);
              setFilterTitle(item);
            }}
            active={activeFilter === item}
          >
            {item}
          </Selection>
        ))}
      </SelectionContainer>
      {isMobile && (
        <SelectionContainer>
          <SelectionTitle>Works With</SelectionTitle>

          {WORKS_WITH.map(item => (
            <MultiSelectionWrapper key={item}>
              <MultiSelection
                type="checkbox"
                onChange={() => handleMultiSelect(item)}
              />
              <span>{item}</span>
            </MultiSelectionWrapper>
          ))}
        </SelectionContainer>
      )}
    </CategoryCol>
  );
};

export default Categories;
