import { CHUNK_SIZE, COLLECTIONS } from "../lib/constants";

export const cleanData = data => {
  const returnArr = [];
  const removeEmpty = data.filter(item => item.length >= 1);
  for (let i = 0; i < removeEmpty.flat().length; i += CHUNK_SIZE) {
    returnArr.push(removeEmpty.flat().slice(i, i + CHUNK_SIZE));
  }
  return returnArr;
};

export const handleFilterType = (filter, item) => {
  if (filter === "All") {
    return item;
  } else if (COLLECTIONS.includes(filter)) {
    return item.filter(el => el.node.frontmatter.tags.includes(filter));
  } else {
    return item.filter(el => el.node.frontmatter.categories.includes(filter));
  }
};

export const formatSearchFilterTitle = (results, query) => {
  const searchResultLength = results.flat().length;
  const resultText = searchResultLength === 1 ? "Result" : "Results";
  const returnString = query
    ? `${searchResultLength} ${resultText} for "${query}"`
    : `${searchResultLength} ${resultText}`;
  return returnString;
};

export const getAllCategoriesInUse = integrations => {
  const categoryArr = [];
  integrations.forEach(item =>
    item.node.frontmatter.categories.forEach(el => categoryArr.push(el))
  );
  return filterDuplicatesHelper(categoryArr);
};

const filterDuplicatesHelper = array => {
  const filteredArray = array.filter((v, i, self) => {
    return i == self.indexOf(v);
  });
  return filteredArray.sort();
};

export const prefixDataWithFeaturedPosts = data => {
  const featured = data.filter(item =>
    item.node.frontmatter.tags.includes("Featured")
  );
  const withoutFeatured = data.filter(
    item => !item.node.frontmatter.tags.includes("Featured")
  );
  return [...featured, ...withoutFeatured];
};

export const scrollToForm = id => {
  const references = document.querySelector(id);
  references.scrollIntoView({
    behavior: "smooth",
  });
};

export const findQueryInIntegration = (query, data) => {
  const match = data.map(item => {
    const found = item.filter(
      el =>
        el.node.frontmatter.appName
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        el.node.frontmatter.companyName
          .toLowerCase()
          .includes(query.toLowerCase()) ||
        el.node.frontmatter.shortDescription
          .toLowerCase()
          .includes(query.toLowerCase())
    );
    return found;
  });
  return match;
};
