import FormWrapper from "components/form/FormWrapper";
import RhombusForm from "components/form/RhombusForm";
import React from "react";
import {
  FormCol,
  FormSectionContainer,
  FormTitleSection,
} from "./styles/MarketPlace-css";
import {
  SectionCol,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitle,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import useIsMobile from "hooks/useIsMobile";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "linaria";
import { BREAK_POINT } from "./lib/constants";

const backgroundImage = css`
  z-index: 0 !important;
  @media (max-width: 600px) {
    display: none;
  }
`;

const FormSection = ({ formId }) => {
  const { isMobile } = useIsMobile(BREAK_POINT);
  return (
    <FormSectionContainer id="marketplace-form">
      <StaticImage
        style={{
          position: "absolute",
          top: 0,
          left: "-200px",
          height: "100%",
          width: "700px",
          pointerEvents: "none",
        }}
        className={backgroundImage}
        layout="fullWidth"
        alt=""
        src={"./img/rhombus-pattern-min.png"}
        formats={["auto", "webp", "avif"]}
        placeholder="none"
        backgroundColor="transparent"
        objectFit="contain"
      />
      <SectionInner>
        <SectionCol>
          <FormTitleSection>
            <FancyTitle>Looking for a Specific Integration?</FancyTitle>
            <MainParagraph>
              The Rhombus Engineering Team releases new features and
              integrations every month. If you would like to request a specific
              third-party integration, let us know!
            </MainParagraph>
          </FormTitleSection>
        </SectionCol>
        <FormCol>
          <FormWrapper width={isMobile ? "100%" : "586px"} title="Get in Touch">
            <RhombusForm formId={formId} />
          </FormWrapper>
        </FormCol>
      </SectionInner>
    </FormSectionContainer>
  );
};

export default FormSection;
