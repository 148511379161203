import {
  FancyPageTitle,
  MainParagraph,
  PreTitle,
} from "components/rhombus-UI/theme/typography";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import {
  HeaderContainer,
  HeaderInner,
  HeaderLeft,
  HeaderRight,
  HeaderTextContainer,
  HeroImageWrapper,
  ImageClass,
} from "./styles/MarketPlace-css";

const MarketPlaceHeader = () => {
  return (
    <HeaderContainer>
      <HeaderInner>
        <HeaderLeft>
          <HeaderTextContainer>
            <PreTitle>Integrations</PreTitle>
            <FancyPageTitle>
              Build Your All-In-One Security Solution
            </FancyPageTitle>
            <MainParagraph>
              Your security, your way. Integrate with your favorite third-party
              solutions to streamline operations, add functionality, and get
              more out of your existing tech stack.
            </MainParagraph>
          </HeaderTextContainer>
        </HeaderLeft>
        <HeaderRight>
          <HeroImageWrapper>
            <StaticImage
              src="./img/marketplace-header.png"
              alt="rhombus marketplace"
              className={ImageClass}
              loading="eager"
              placeholder="blurred"
              objectFit="contain"
            />
          </HeroImageWrapper>
        </HeaderRight>
      </HeaderInner>
    </HeaderContainer>
  );
};

export default MarketPlaceHeader;
