import React from "react";

const Adjustments = ({
  color = "currentColor",
  height = "20",
  width = "24",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 34 28`}
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.653 0.5C19.4953 0.5 18.3839 0.954363 17.5635 1.76501C17.0229 2.29923 16.6378 2.9594 16.438 3.67647H1.65753C1.02389 3.67647 0.5 4.18561 0.5 4.8262C0.5 5.46679 1.02389 5.97594 1.65753 5.97594H16.438C16.6378 6.693 17.0229 7.35317 17.5635 7.88739C18.3839 8.69804 19.4953 9.15241 20.653 9.15241C21.8106 9.15241 22.922 8.69804 23.7424 7.88739C24.2831 7.35317 24.6681 6.693 24.8679 5.97594H32.3425C32.9761 5.97594 33.5 5.46679 33.5 4.8262C33.5 4.18561 32.9761 3.67647 32.3425 3.67647H24.8679C24.6681 2.95941 24.2831 2.29923 23.7424 1.76501C22.922 0.954363 21.8106 0.5 20.653 0.5ZM19.1963 3.39519C19.5816 3.01443 20.1055 2.79947 20.653 2.79947C21.2005 2.79947 21.7243 3.01443 22.1097 3.39519C22.4948 3.77575 22.71 4.29062 22.71 4.8262C22.71 5.36179 22.4948 5.87666 22.1097 6.25722C21.7243 6.63798 21.2005 6.85294 20.653 6.85294C20.1055 6.85294 19.5816 6.63798 19.1963 6.25722C18.8111 5.87666 18.5959 5.36179 18.5959 4.8262C18.5959 4.29062 18.8111 3.77575 19.1963 3.39519Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.54795 10.607C8.39029 10.607 7.27887 11.0613 6.45849 11.872C5.99127 12.3336 5.64024 12.8894 5.42457 13.4947H1.65753C1.02389 13.4947 0.5 14.0038 0.5 14.6444C0.5 15.285 1.02389 15.7941 1.65753 15.7941H5.26324C5.43319 16.6222 5.84598 17.3891 6.45849 17.9943C7.27887 18.805 8.39029 19.2594 9.54795 19.2594C10.7056 19.2594 11.817 18.805 12.6374 17.9943C13.2499 17.3891 13.6627 16.6222 13.8327 15.7941H32.3425C32.9761 15.7941 33.5 15.285 33.5 14.6444C33.5 14.0038 32.9761 13.4947 32.3425 13.4947H13.6713C13.4556 12.8894 13.1046 12.3336 12.6374 11.872C11.817 11.0613 10.7056 10.607 9.54795 10.607ZM8.09125 13.5021C8.47659 13.1214 9.00046 12.9064 9.54795 12.9064C10.0954 12.9064 10.6193 13.1214 11.0046 13.5021C11.3898 13.8827 11.605 14.3976 11.605 14.9332C11.605 15.4687 11.3898 15.9836 11.0046 16.3642C10.6193 16.7449 10.0954 16.9599 9.54795 16.9599C9.00046 16.9599 8.47659 16.7449 8.09125 16.3642C7.70613 15.9836 7.49087 15.4687 7.49087 14.9332C7.49087 14.3976 7.70613 13.8827 8.09125 13.5021Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.621 19.8476C24.4634 19.8476 23.3519 20.302 22.5315 21.1126C21.919 21.7178 21.5062 22.4847 21.3363 23.3128H1.65753C1.02389 23.3128 0.5 23.822 0.5 24.4626C0.5 25.1032 1.02389 25.6123 1.65753 25.6123H21.4976C21.7133 26.2176 22.0643 26.7733 22.5315 27.235C23.3519 28.0456 24.4634 28.5 25.621 28.5C26.7787 28.5 27.8901 28.0456 28.7105 27.235C29.1777 26.7733 29.5287 26.2176 29.7444 25.6123H32.3425C32.9761 25.6123 33.5 25.1032 33.5 24.4626C33.5 23.822 32.9761 23.3128 32.3425 23.3128H29.9057C29.7358 22.4847 29.323 21.7178 28.7105 21.1126C27.8901 20.302 26.7787 19.8476 25.621 19.8476ZM24.1643 22.7428C24.5496 22.362 25.0735 22.1471 25.621 22.1471C26.1685 22.1471 26.6924 22.362 27.0777 22.7428C27.4628 23.1233 27.6781 23.6382 27.6781 24.1738C27.6781 24.7094 27.4628 25.2243 27.0777 25.6048C26.6924 25.9856 26.1685 26.2005 25.621 26.2005C25.0735 26.2005 24.5496 25.9856 24.1643 25.6048C23.7792 25.2243 23.5639 24.7094 23.5639 24.1738C23.5639 23.6382 23.7792 23.1233 24.1643 22.7428Z"
        fill={color}
      />
    </svg>
  );
};

export default Adjustments;
